<template>
  
  <div class="height_100">
  
    <div class="container-card height_100">
      <CCard class="height_100">
        <CCardBody style="max-height: 100vh;overflow: scroll;">     
          <!-- <a href="javascript:void(0)" @click="$router.push('/ext')" style="color:black !important">Go to DN</a> -->
          <div style="display: flex; flex-wrap: nowrap; justify-content: space-between;">
            <CButton v-if="filterType !='truck'" class="btn" @click="handleRouteChange">
              Go to <b>Delivery view</b>  
            </CButton>
            <div v-if="filterType=='plant' && availablePlants.length > 1" style="display: flex;  align-items: center; margin-right: 10px; max-width: 30%;" >
              <label style="margin-bottom: 0; margin-right: 10px;">Plant selected: </label>
              <MultiSelect 
              style="margin-bottom: 0px; max-width: 60%;"
              :value="selectedPlants" 
              :options="availablePlants" 
              appendTo='body'
              optionLabel="" 
              placeholder=""
              @change="updateActivePlant"
              />
              <!--<CSelect
              style="margin-bottom: 0px;"
              :options="availablePlants"
              :value.sync="selectedPlant"
              @change="updateActivePlant()"
              /> -->
            </div>
            <div>
            <CButton
              size="sm"
              class=""
              style="margin-right: 1rem;"
              @click="clearFilters()"
              >
                <CIcon name="cilClearAll" style="text-align:center; cursor: pointer;"  />
              </CButton>
            <CButton
              size="sm"
              class=""
              @click="fullScreen()"
            >
            
            
            <CIcon name="cilFullscreen" style="text-align:center; cursor: pointer;"  />
            </CButton>
          </div>
          </div>
          
          <CDataTable
            class="custom-dt"
            :items="trucksFiltered"
            :fields="tableFields"
            :items-per-page="itemsPerpage"
            @row-clicked="rowClicked"
            pagination
            hover
            sorter
            :sorterValue="{ column: 'truck_plate', asc: true }"
            column-filter
          >
          <template #select_truck-filter>
        <CInputCheckbox
          style="padding-left: 2rem"
          class="truck-checkbox truck-checkbox-filter"
          @update:checked="triggerTruckAll"
        />
      </template>
      <template #select_truck="{ item }">
              <td class="action-column">
                <CInputCheckbox 
                  class="truck-checkbox"
                  :data-truckplate=item.truck_plate
                  :checked.sync="item.checked"
                  @update:checked="triggerTruck(item)"
                />
              </td>
            </template>
            <template #truck_plate-filter="{ item }">
              <div class="multiselect-filter">
                <CInput 
                  class="multiselect-filter"
                  style="align-items: center; text-align: center;"
                  v-model="deliveryFilters.truck_plate"
                  @keydown.enter="handleEnter()"
                />
              </div>
            </template>
            <template #plant-filter="{ item }">
              <div class="multiselect-filter">
                <CInput                   
                  style="align-items: center; text-align: center;"
                  v-model="deliveryFilters.plant"
                  @keydown.enter="handleEnter()"
                />
              </div>
            </template>
            <template #carrier_name-filter="{ item }">
              <div class="multiselect-filter">
                <CInput                   
                  style="align-items: center; text-align: center;"
                  v-model="deliveryFilters.carrier_name"
                  @keydown.enter="handleEnter()"
                />
              </div>
            </template>
            <template #position_timestamp-filter="{ item }">
              <BlockInfoDatePicker
                  :disabled="false"
                  type="date"
                  v-model="deliveryFilters.position_timestamp"
                  @input="handleEnter()"
                />
            </template>
          <template #truck_plate="{ item }">
              <td style="vertical-align: top;">
                <span
                  style="color: #1D4370;"
                >
                  {{ item.truck_plate }}
              </span>
              </td>
            </template>   
            <template #vehicle_type-filter="{item}">
              <MultiSelect class="multiselect-filter" v-model="deliveryFilters.vehicle_type" 
              :options="vehicleTypeSelect" 
              appendTo='body'
              optionLabel="vehicle_type" 
              placeholder=""
              @change="handleEnter" />
            </template>
            <template #haulier_type-filter="{item}">
              <MultiSelect class="multiselect-filter" v-model="deliveryFilters.haulier_type" 
              :options="contTypeSelect" 
              appendTo='body'
              optionLabel="haulier_type" 
              placeholder=""
              @change="handleEnter" />
            </template>
            <template #vehicle_type="{ item }">
              <td>
                
                  {{ item.vehicle_type || "" }}
              </td>
            </template> 
            
            <template #haulier_type="{ item }">
              <td>
                
                  {{ item.haulier_type || "" }}
              </td>
            </template> 
            <template #carrier_name="{ item }">
              <td>
                
                  {{ item.carrier_name || "" }}
              </td>
            </template> 
            <template #position_timestamp="{ item }">
              <td >
                <span
                  
                >
                  {{ moment(item.position_timestamp).format("DD/MM/YYYY HH:mm") }}
              </span>
              </td>
            </template>   
              
          
            <template #actions="{ item }">
              <td class="action-column">
                <i class="icon pi pi-chevron-right"></i>
              </td>
            </template>
           </CDataTable>
           <CPagination
            :pages="totalPages"
            :active-page="currentPage"
            @update:activePage ="handlePagination"
            />
           <div style="display: flex;justify-content: flex-end;">
            <CInputCheckbox 
            class="truck-checkbox"
            style="margin-right: 10px;"
            label="Show inactive trucks"
            :checked.sync="showInactive"
            @change="showInactiveChange()"
            />
            <CInputCheckbox
            class="truck-checkbox" 
            style="margin-right: 10px;"
            label="Show all plants on map"
            :checked.sync="showPlants"
            @change="showPlantsChange()"
            />
            
          </div>
        </CCardBody>
      </CCard>

    </div>
    <transition name="slide">
      <TruckOnlyDetail 
      v-if="showTruckDetail"
       @close="closeTruckDetail"
       v-bind:truck="truckDetail"
        />
    </transition>
  </div>
</template>

<script>
import TruckOnlyDetail from "./TruckOnlyDetail.vue";
import * as gmaps from "../plugins/here";
import { store } from "../store/store";
import moment from 'moment';
import * as Socket from "../plugins/socket_client";
import MultiSelect from 'primevue/multiselect';
import BlockInfoDatePicker from "../components/BlockInfoDatePicker.vue";
import "vue2-datepicker/index.css"; 
import Vue from "vue"
export default {
  name: "Trucks",
  components: { TruckOnlyDetail,MultiSelect, BlockInfoDatePicker },
  props: { totalPages: Number, currentPage: Number, deliveryFilters: Object, vehicleTypeSelect: Array, contTypeSelect: Array},
  computed: {
    trucksState() {
      return store.state.trucks.filter(x=>x.mode =='truck')
      // .map(x=>{
      //  return {...x, position_timestamp : moment(x.position_timestamp).valueOf()}
      // })
    },
   
    trucksFiltered(){
      var trucks = this.trucksState;
    
      if(!this.showInactiveFilter){
        trucks = trucks.filter(x=>this.isTruckActive( x.position_timestamp));

      }
      if(this.selectedVehicleType.length > 0){
        trucks = trucks.filter(x=>this.selectedVehicleType.includes(x.vehicle_type));
      }
      if(this.selectedContType.length > 0){
        trucks = trucks.filter(x=>this.selectedContType.includes(x.haulier_type));
      }
      // if(this.lastEventFilter){
      //   trucks = trucks.filter(x=>this.getEventFromEventName(x.last_event).toLowerCase().includes(this.lastEventFilter.toLowerCase()));
      // }
      // store.commit('setTrucks', trucks)  
      trucks = trucks.filter(x => x.show)
      return trucks;
    },
    showInactiveFilter(){
      return store.state.filters.showInactive
    },
    availablePlants(){
      return store.state.availablePlants
    },
    selectedPlantStore(){
      return store.state.selectedPlant
    },
    filterType(){
      return store.state.filterType
    },
    checkedTrucks(){
      return store.state.trucks.filter(x=>x.mode =='truck' && x.checked).map(x=>x.truck_plate)
    },
    tableFields(){
      var tableFields = [
      {
          key: "select_truck",
          label: "",
          sorter: false,
          filter: false,
        },
        {
          key: "truck_plate",
          label: "Truck plate",
          sorter: false,
          filter: false,
        },
        {
          key: "plant",
          label: "Plant",
          sorter: false,
          filter: false,
        },
        {
          key: "position_timestamp",
          label: "Last position",
          sorter: false,
          filter: false,
        },
        { key: "vehicle_type", label: "Vehicle type", sorter: false,
          filter: false, },
    { key: "haulier_type", label: "Cont type", sorter: false,
          filter: false, },
        {
          key: "carrier_name",
          label: "Carrier",
          sorter: false,
          filter: false,
        },
        {
          key: "actions",
          label: "",
          sorter: false,
          filter: false,
        },
      ];

      // if(this.trucksState.find(x=>x.vehicle_type != null)){
      //   tableFields.splice(tableFields.length-1,0,{
      //     key: "vehicle_type",
      //     label: "Vehicle type",
      //   })
      // }
      // if(this.trucksState.find(x=>x.haulier_type != null)){
      //   tableFields.splice(tableFields.length-1,0,{
      //     key: "haulier_type",
      //     label: "Cont type",
      //   })
      // }
      
      // if(this.trucksState.find(x=>x.carrier_name != null)){
      //   tableFields.splice(tableFields.length-1,0,{
      //     key: "carrier_name",
      //     label: "Carrier",
      //   })
      // }

      return tableFields

    },
    selectedPlants() {
      return store.state.selectedPlants;
    }
    // vehicleTypeSelect(){
    //   return this.trucksState.map(x=>x.vehicle_type).filter((value, index, self) => self.indexOf(value) === index && value != null)
    // },
    // contTypeSelect(){
    //   return this.trucksState.map(x=>x.haulier_type).filter((value, index, self) => self.indexOf(value) === index && value != null)
    // }
    
  },

  data() {
    return {
      moment:moment,
      resultTable: [],
      collapseDuration: 0,
      collapse: true,
      showTruckDetail: false,
      truckDetail: null,
      lastEventFilter: null,
      showInactive:false,
      showPlants:false,
      selectedPlant:null,
      filteredItems:null,
      selectedVehicleType: [],
      selectedContType:[],
      itemsPerpage: 10,
    
    };
  },
  watch: {
    selectedPlantStore(newData, oldData) {
      if(newData != this.selectedPlant){
        this.selectedPlant = newData
        if (this.selectedPlant != null && this.currentPage == 1) {
          if (this.selectedPlants.length == 0) {
            store.commit("setSelectedPlants", this.selectedPlant);
          }
          
          this.$emit('loading', true)
          this.$emit("getDropdownValues")
          
          this.getTruckPagination()
        }
      }
    }

  },
  created() {},

  async mounted() {
    let showInactive = sessionStorage.getItem('showInactive');
    if (showInactive && showInactive == 'Y') this.showInactive = true
    this.showInactiveChange();
    this.showPlantsChange();
    this.selectedPlant = this.$store.state.selectedPlant
    store.commit('setFiltering', false)
    if (this.selectedPlants.length == 0) {
      store.commit("setSelectedPlants", this.selectedPlant);
      
    }
  },
  methods: {
    lastEventColor(truck) {
      var color = this.getColorFromEventName(truck.last_event);
      return { "background-color": color };
    },
    clearFilters() {
      this.$emit('clear-filters', true);
      this.handleEnter()
    }, 
    focusTruck: (truck) => {
      gmaps.focusTruck(truck.truck_plate);
    },
    viewRoute: (truck) => {
      gmaps.viewRoute(truck.truck_plate);
    },
    handleRouteChange() {
      this.$router.push('/ext');
      this.$store.state.trucks = [];
      this.$store.state.markers = [];
      Socket.leaveRooms();
      this.$store.state.trucksEvent = false;
    },
    getTruckPagination() {
      this.$emit('loading', true)
      Vue.prototype.$http.post('/api/geolocation/truck/' + this.itemsPerpage + '/' + this.currentPage, {
        isActive: store.state.filters.showInactive,
        filters: this.deliveryFilters,
        plant: this.selectedPlants

      }).then(res => {
        store.commit('setTrucks', res.data)
        this.$emit('updateTotalPages', Math.ceil(store.state.totalTrucks / this.itemsPerpage) )
        this.$emit('loading', false)
      })
    },
    handlePagination(item) {
      let self = this;
      this.$emit('updateCurrentPage', item)
      if (this.selectedPlantStore != null) {
        this.$emit('truck-filter', { currentPage: item, perPage:self.itemsPerpage, plant: this.selectedPlant})
      }
    },   
    async handleEnter() {
      this.$emit('loading', true)
      sessionStorage.setItem('userFilters', JSON.stringify(this.deliveryFilters));
      await this.$emit('updateCurrentPage', 1);
      Vue.prototype.$http.post('/api/geolocation/truck/' + this.itemsPerpage + '/' + this.currentPage, {
        filters: this.deliveryFilters,
        isActive: store.state.filters.showInactive,
        plant: this.selectedPlants

      })
      .then(res => {
        store.commit('setTrucks', res.data);
        store.commit('setFiltering', true);
        this.$emit('updateTotalPages', Math.ceil(store.state.totalTrucks / 10))
        this.$emit('loading', false)

      }).catch((e) => {
        this.$emit('loading', false)
      })
    },
    toggleDetails(item) {
      item._toggled = !item._toggled;
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    fullScreen() {
      this.$emit("fullScreenMap", true);
    },
    openDetails(item) {
      this.truckDetail = item;
      this.showTruckDetail = true;
      this.$emit("fullScreenMap", true);
      this.$emit("openedDetails", true);
    },
    rowClicked(item, index, column_name, event) {
      if(column_name != 'select_truck')
        this.openDetails(item)
      // console.log("rowClicked", item, index, column_name, event);
    },
    filteredItemsChange(items) {
      this.filteredItems = items;
    },
    closeTruckDetail() {
      this.showTruckDetail = false;
      this.$emit("fullScreenMap", false);
      this.$emit("openedDetails", false);
    },
    setLastEventFilter(e) {
      var value= e.target.value;
     this.lastEventFilter = value//.split(' ').join('');
    },
    showInactiveChange(){
      store.commit("showInactive", this.showInactive);
      if (this.showInactive) {
        sessionStorage.setItem('showInactive', 'Y');
      } else {
        sessionStorage.setItem('showInactive', 'N');
      }
      let self = this;
      if (this.selectedPlantStore != null) {
        console.log('infinito')
         this.$emit('truck-filter', { currentPage: self.currentPage, perPage:self.itemsPerpage})
         this.$emit('getDropdownValues')

      }
      
    },
    showPlantsChange(){
      store.commit("showPlants", this.showPlants);
    },
    updateActivePlant(selectedPlant){
      //Socket.setPlant(this.selectedPlant);
      //this.$emit('updateCurrentPage', 1);
      store.commit("setSelectedPlants", selectedPlant.value);
      this.$emit('updateCurrentPage', 1);
      this.$emit('truck-filter', {currentPage: 1, perPage: this.itemsPerpage})
      sessionStorage.setItem('userSelectedPlants', JSON.stringify(this.selectedPlants));
    },
    triggerTruck(item, checked,obj){
      if (item.checked) {
        Socket.truckChecked(item)
      } else {
        Socket.truckUnChecked(item)
      }
      gmaps.calculateTruckVisibility(this.showInactiveFilter,this.checkedTrucks);
    },
    async triggerTruckAll(checked,obj){
      var payload ={
        checked: checked,
        filteredItems: this.filteredItems
      }
      payload.mode = "truck"
      payload.currentPage = this.currentPage;
      this.$emit('loading', true)

      if (checked) {
        await Vue.prototype.$http.post('/api/geolocation/truck/' + 10 + '/' + this.currentPage, {
        isActive: store.state.filters.showInactive,
        triggerAll: payload.checked,
        filters: this.deliveryFilters,
        plant: this.selectedPlants

      }).then(async res => {
        // store.commit('setTrucksDn', res.data, {triggerAll: true})
        payload.trucks = res.data;
       
       
        this.$emit('updateTotalPages', Math.ceil(store.state.totalTrucks / 10)) 
        store.commit("triggerTruckAll", payload);
        //if (!this.$store.state.trucksEvent) {
        Socket.checkAllTrucks(res.data.map(dn => ({ truck_plate: dn.truck_plate, plant: dn.plant })))
          this.$store.state.trucksEvent = true;
        //}
        
      })
      } else {
        store.commit("untriggerAll", payload);
        Socket.leaveRooms()
      }
      this.$emit('loading', false)

    }
  },
};
</script>
<style>
.truck-checkbox input{
  position: initial;
  height: 25px !important;
  width: 25px !important;
}
.truck-checkbox-filter input{
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.truck-checkbox > .form-check-label{
  vertical-align: super;
  padding-left: 5px;
}

</style>
<style lang="scss" scoped>
.multiselect-filter{
  margin-top: 20px;
  margin-bottom: 20px;
  height: 48px;
  max-width: 120px;
}
.btn {
  border: 1px solid #d8d8d8;
  padding: 0.5em 2em;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000;
  border-radius: 2px;
}

.focus-truck-btn {
  white-space: nowrap;
  margin: 2px;
  padding: 5px;
  /* padding-top: 0px;
padding-bottom: 0px;
padding-left: 5px;
padding-right: 5px; */
}
.action-column {
  // display: flex;
}
.table th,
.table td {
  vertical-align: middle;
}

::v-deep .custom-dt {
  input {
    border-radius: 2px;
    border: 1px solid #d8d8d8;
    box-shadow: none;
    height: 48px;
  font-size: 16px;

  }

  thead {
    font-weight: 700;
    font-size: 14px;
    color: #888;
  }

  table thead tr th {
   
    border-bottom: none;
    
    input{
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  table thead tr:first-child th{
    border-top: none;
  }

  td {
    color: black;
  }
  tr{
    cursor: pointer;
  }
}
::v-deep .container-card {
  .card {
    border: none !important;
    border-radius: 0 !important;
  }
}


.slide-enter-active,
    .slide-leave-active
    {
        transition: transform 250ms ease;
        ::v-deep .shadow-truckDetail{
          // transition: transform 1s ease;
          transition: all 250ms ease;
          opacity: 0.5;
          width: 400vw;
        }
    }

    .slide-enter,
    .slide-leave-to {
        transform: translateX(-100%);
        transition: all 250ms ease-in 0s;
        ::v-deep .shadow-truckDetail{
          transition: all 250ms ease-in 0s;
          opacity: 0;
        }
    }
</style>
